<template>
  <div class="introduce-box">
    <div class="introduce-item" v-for="(item,idx) in introduceList" :key="item.title"
         :style="{flexDirection: !direction ? (idx % 2 !== 1 ? 'row' : 'row-reverse') : (idx % 2 === 1 ? 'row' : 'row-reverse')}">
      <img :src="item.img" alt="">
      <div class="introduce-info" :style="{marginLeft:!direction && idx % 2 !== 1 ? '100px' : (direction && idx % 2 === 1 ? '100px' : ''), marginRight:!direction && idx % 2 === 1 ? '100px' : (direction && idx % 2 !== 1 ? '100px' : '') }">
        <div class="title" :style="{textAlign:!direction ? (idx % 2 !== 1 ? '' : 'right') : (idx % 2 === 1 ? '' : 'right') }" >{{$t(item.title)}}</div>
        <p style="margin:0;">{{ $t(item.content) }}</p>
        <img :src="item.contImg" alt="" :style="{marginLeft:idx % 2 !== 1 || imgRight  ? '' : '174px',width:item.imgWidth ? item.imgWidth : '' }">
        <div class="sub-contnet" v-if="item.subContent.length" :style="{textAlign: direction && idx % 2 !== 1 ? 'right' : ''}">
          <span style="display: inline-block;" v-for="subContent in item.subContent" :key="subContent">{{ $t(subContent) }}<br/></span>
<!--          :style="{transform: direction && idx === 0 ? 'translate(-24px)' : ''}"-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    introduceList: Array,
    direction: String,
    imgRight:Boolean
  }
};
</script>

<style scoped lang="scss">
.introduce-box {
  margin-bottom: 110px;
  width: 100%;

  .introduce-item {
    display: flex;
    align-items: center;
    max-width: 1700px;
    margin: 117px auto 0;
    justify-content: center;

    & > img {
      width: 60%;
    }

    .introduce-info {
      text-align: left;
      padding: 0 10px;

      .title {
        font-size: 42px;
        margin-bottom: 20px;
      }

      p {
        min-width: 430px;
        text-align: left;
        line-height: 1.6;
        font-weight: 100;
      }

      img {
        margin-top: 42px;
      }

      .sub-contnet {
        white-space: pre-line;
        margin-top: 100px;
        min-width: 430px;
        font-size: 12px;
        font-weight: 100;
      }
    }
  }

  .introduce-item + .introduce-item {
    margin-top: 217px;
  }

  @media (max-width: 600px) {


  }
}
</style>